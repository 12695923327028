body {
  text-transform: uppercase;
  color: #302e26;
  height: 100vh;
  height: calc(100 * var(--vh));
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0 2vw;
  font-family: arial, helvetica, sans-sertif;
  font-size: 48px;
  font-weight: 400;
  display: flex;
}

h1, h2, h3, h4 {
  font-weight: inherit;
  font-size: inherit;
}

main {
  z-index: 1;
  width: 1016px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
  overflow: hidden;
}

main img, main iframe {
  width: 100%;
  max-width: 1001px;
  display: block;
}

main iframe {
  aspect-ratio: 1.5;
}

main .main-image-wrap {
  width: 30%;
  position: absolute;
  top: 20%;
  left: 35%;
}

main .main-image-wrap img {
  width: 100%;
  display: block;
}

header {
  color: #010101;
  z-index: 2;
  margin-left: 7vw;
  font-size: 2.5vw;
  line-height: 2em;
  position: absolute;
  top: 9.5vw;
  left: 0;
}

header span.date {
  color: #cdcdc7;
}

header span.time {
  color: #f6f6f0;
}

header .ghost {
  color: #322d23;
}

header p, header h1, header h2, header h3 {
  margin: 0;
}

.image-wrap-link {
  color: #0000;
  display: block;
  position: absolute;
  left: 22%;
}

.image-wrap-cv-link {
  width: 10%;
  height: 10%;
  bottom: 31%;
}

.image-wrap-mail-link {
  width: 18%;
  height: 10%;
  bottom: 48%;
}

/*# sourceMappingURL=contact.b11d3c98.css.map */
