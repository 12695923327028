body {
  font-family: arial, helvetica, sans-sertif;
  font-weight: 400;
  font-size: 48px;
  text-transform: uppercase;
  color: #302e26;
  padding: 0;
  margin: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  height: calc(100 * var(--vh));
  padding: 0 2vw;
}

h1,h2,h3,h4 {
  font-weight: inherit;
  font-size: inherit;
}


main {
  overflow: hidden;
  position: relative;
  z-index: 1;
  // height: 0;
  width: 1016px;
  // height: 645px;

  // max-width: 100%;
  // padding-top: 63.48%;
  // background-image: url(../images/bg-full-flattened.jpg);
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  // margin-bottom: 15px;

  // border: 1px solid red;

  img, iframe {
    width: 100%;
    max-width: 1001px;
    display: block;
  }

  iframe {
    aspect-ratio: 1.5;
  }
  
  .main-image-wrap {
    position: absolute;
    left: 35%;
    width: 30%;
    top: 20%;

    img {
      width: 100%;
      display: block;
    }
  }
}

header {
  color: #010101;
  margin-left: 7vw;
  // margin-top: 12vh;
  line-height: 2em;
  z-index: 2;
  position: relative;
  font-size: 2.5vw;
  position: absolute;
  left: 0;
  top: 9.5vw;
  span.date {
    color: #cdcdc7;
  }

  span.time {
    color: #f6f6f0;
  }

  .ghost {
    color: #322d23;
  }

  p, h1, h2, h3 {
   margin: 0; 
  }
}

.image-wrap-link {
  display: block;
  position: absolute;
  left: 22%;
  color: transparent;
}

.image-wrap-cv-link {
  width: 10%;
  height: 10%;
  bottom: 31%;
}

.image-wrap-mail-link {  
  width: 18%;
  height: 10%;
  bottom: 48%;
}